import React, { useState } from "react";
import { DayPicker } from "react-day-picker";
import "react-datepicker/dist/react-datepicker.css"; // Import the styles
import classes from "./style/slider.module.css";
import "../../Styles/dateInput.css";

function DateInput({ disabledDates, disabledDays, triggerNextStep }) {
  const today = new Date();
  today.setHours(0, 0, 0, 0); // Set the time to midnight

  // Array of disabled dates
  // const disabledDates = [
  //   "2023-10-25T21:00:00.000Z",
  //   "2023-10-26T22:00:00.000Z",
  //   "2023-10-27T22:00:00.000Z",
  //   "2023-10-28T22:00:00.000Z",
  //   "2023-10-29T22:00:00.000Z",
  // ];

  // const disabledDays = [0, 3];

  // Custom disabled function to prevent selecting dates before today and disabled dates
  const isBeforeTodayOrDisabled = (date) => {
    if (date === null) {
      return true; // Handle null date to prevent errors
    }
    const selectedDatee = new Date(date);
    const dayOfWeek = selectedDatee.getDay(); // 0 for Sunday, 1 for Monday, and so on
    return (
      date < today ||
      disabledDates.includes(date.toISOString()) ||
      disabledDays.includes(dayOfWeek)
    );
  };

  const [selectedDate, setSelectedDate] = useState(null); // Use null instead of an empty string

  const handleDateSelect = (date) => {
    const inputDate = new Date(date);
    const formattedDate = inputDate.toLocaleDateString("en-GB");
    triggerNextStep({ value: `Selected date: ${formattedDate}` });
  };

  // const handleDateChange = (date) => {
  //   if (date) {
  //     const currentDate = new Date();
  //     // Check if the selected date is in the past
  //     if (date < currentDate) {
  //       // You can display an error message or handle it in any way you prefer
  //       alert("You cannot choose a date that has passed.");
  //     } else {
  //       setSelectedDate(date);
  //     }
  //   }
  // };

  // const MyContainer = ({ children }) => {
  //   return (
  //     <div style={{ padding: "16px", color: "black" }}>
  //       <div style={{ background: "#f0f0f0" }}>What is your favorite day?</div>
  //       <div style={{ position: "relative" }}>{children}</div>
  //     </div>
  //   );
  // };

  // ll text
  // padding: 10px;
  // display: flex;
  // justify-content: center;
  // align-items: center;
  // font-size: 14px;

  return (
    <div className={classes.calnder_container}>
      {/* <div className={classes.header}>
        <p className="text">{theHeader}</p>
      </div> */}
      <div className={classes.datePicker}>
        <DayPicker
          // mode="single"
          selected={selectedDate}
          onDayClick={handleDateSelect} // Use onDayClick for selecting dates
          // inline
          // dateFormat="yyyy-MM-dd"
          disabled={(date) => isBeforeTodayOrDisabled(date)}
          // calendarContainer={MyContainer}
          style={{
            fontSize: "16px",
            backgroundColor: "white",
            border: "1px solid #ccc",
            borderRadius: "4px",
            boxShadow: "0 2px 6px rgba(0, 0, 0, 0.1)",
            width: "300px",
            maxWidth: "100%",
            padding: "10px",
          }}
        />
      </div>
    </div>
  );
}

export default DateInput;