import * as React from "react";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import CardHeader from "@mui/material/CardHeader";
import Avatar from "@mui/material/Avatar";

export default function MediaCard({ img, title, counting }) {
  console.log("al text", title);
  return (
    <Card sx={{ maxWidth: 300 }}>
      <CardHeader
        avatar={
          <Avatar
            sx={{
              bgcolor: "#6e48aa",
              color: "white",
              fontSize: "25px",
              fontWeight: "bold",
            }}
            aria-label="recipe"
          >
            {counting + 1}
          </Avatar>
        }
        title={title}
        titleTypographyProps={{
          style: {
            fontSize: "1.2rem",
            fontWeight: "bold",
          },
        }}
      />
      <CardMedia sx={{ height: 320 }} image={img} />
    </Card>
  );
}
