// import React from "react";
// import ChatBot from "react-simple-chatbot";
// import classes from "../../Styles/chat.module.css";
// import { useTranslation } from "react-i18next"; // Import useTranslation from your i18n library
// import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

// const Chat = () => {
//   const { t } = useTranslation();

//   const steps = [
//     {
//       id: "1",
//       message: t("Hello! I am your Dentist  How can I assist you today?"),
//       trigger: "2",
//     },
//     {
//       id: "2",
//       options: [
//         {
//           value: "appointments",
//           label: t("view Appointments"), // Replace 'View Appointments' with the translation key
//           trigger: "appointments",
//         },
//         {
//           value: "services",
//           label: t("dental Services"), // Replace 'Dental Services' with the translation key
//           trigger: "services",
//         },
//         {
//           value: "contact",
//           label: t("contact Information"), // Replace 'Contact Information' with the translation key
//           trigger: "contact",
//         },
//         {
//           value: "exit",
//           label: t("end chat"), // Replace 'Exit' with the translation key
//           // End the conversation
//         },
//       ],
//     },
//     {
//       id: "appointments",
//       message: t("upcoming Appointments"), // Replace 'Here are your upcoming appointments:' with the translation key
//       trigger: "showAppointments",
//     },
//     {
//       id: "showAppointments",
//       component: (
//         <div className={classes.showAppointments}>
//           <ul>
//             <li>
//               {" "}
//               <ArrowForwardIosIcon style={{ fontSize: "12px" }} />{" "}
//               {t("Tue Sep 05 2023 14:24:10")}{" "}
//               {/* Translate the appointment text */}
//             </li>
//             <li>
//               {" "}
//               <ArrowForwardIosIcon style={{ fontSize: "12px" }} />{" "}
//               {t("We Sep 06 2023 18:24:10")}{" "}
//               {/* Translate the appointment text */}
//             </li>
//             <li>
//               {" "}
//               <ArrowForwardIosIcon style={{ fontSize: "12px" }} />{" "}
//               {t("Th Sep 06 2023 18:24:10")}{" "}
//               {/* Translate the appointment text */}
//             </li>
//           </ul>
//         </div>
//       ),
//       trigger: "2",
//     },
//     {
//       id: "services",
//       message: t("dental ServicesList"), // Replace 'We offer the following dental services:' with the translation key
//       trigger: "showServices",
//     },
//     {
//       id: "showServices",
//       component: (
//         <div className={classes.services}>
//           <ul>
//             <li>
//               {" "}
//               <ArrowForwardIosIcon style={{ fontSize: "12px" }} />{" "}
//               {t("teeth Cleaning")} {/* Translate the service text */}
//             </li>
//             <li>
//               {" "}
//               <ArrowForwardIosIcon style={{ fontSize: "12px" }} />{" "}
//               {t("fillings")} {/* Translate the service text */}
//             </li>
//             <li>
//               {" "}
//               <ArrowForwardIosIcon style={{ fontSize: "12px" }} />{" "}
//               {t("tooth Extractions")} {/* Translate the service text */}
//             </li>
//             <li>
//               {" "}
//               <ArrowForwardIosIcon style={{ fontSize: "12px" }} />{" "}
//               {t("root Canals")} {/* Translate the service text */}
//             </li>
//             {/* Add more services here */}
//           </ul>
//         </div>
//       ),
//       trigger: "2",
//     },
//     {
//       id: "contact",
//       message: t("contact Info Label"), // Replace 'Here is our contact information:' with the translation key
//       trigger: "showContact",
//     },
//     {
//       id: "showContact",
//       message: t("address"), // Replace 'Dentist Clinic Address: 123 Main Street, City, ' with the translation key
//       trigger: "showContact2",
//     },
//     {
//       id: "showContact2",
//       message: t("02484858"), // Replace 'Phone: (555) 555-5555' with the translation key
//       trigger: "2",
//     },
//     // ========
//   ];

//   return (
//     <div>
//       <h1>chat</h1>
//       <ChatBot steps={steps} headerTitle="Niyat Clinics" />
//     </div>
//   );
// };

// export default Chat;

import React, { useEffect, useState, useRef } from "react";
import ChatBot from "react-simple-chatbot";
import classes from "../../Styles/chat.module.css";
import SliderCards from "./Slider";
import DateInput from "./DateInput";
import {
  LaserTreatment,
  FacialLaser,
  IMG_8703,
  lipaugmentation,
  amr,
  samer,
  khlood,
  Louie,
  logo,
} from "../../assets/index";
import SliderImgs from "./SliderImgs";
import ChatForm from "./ChatForm";
const InterviewChatbot = () => {
  const [data, setData] = useState([]);
  console.log("data>>>", data && data);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `https://chatbotapi.mygatein.com/chatbot/KBot/getid?iddata={"AccountNumber":50}`
        );
        const data = await response.json();
        console.log(data[0]?.jsonData, "hobee");
        const cleanedData = data[0]?.jsonData.map((item) => {
          // Create a new object with cleaned keys
          const cleanedItem = {
            id: item.id,
            component: item.component,
            waitAction: item.waitAction,
            trigger: item.trigger,
            message: item.message,
          };

          return cleanedItem;
        });
        console.log("cleaded Data", cleanedData);
        setData(cleanedData);

        console.log("all cleaned data", cleanedData);
      } catch (error) {
        console.error("Error fetching data:", error);
        console.log("kol al data", data);
      }
    };
    fetchData();
  }, []);

  const updatedTrigger = data.map((step) => {
    // Check if the step has a trigger property
    if (step.trigger) {
      // Add an onClick function to the step
      step.onClick = () => {
        // Define your custom logic here
        console.log(`Clicked on step with ID: ${step.id}`);
        // You can add more custom logic based on the step
      };
    }
    return step;
  });

  // useEffect(() => {
  //    data[5]?.component === "LaserTreatment"
  // },[data])

  // const [data400, setData400] = useState([]);
  // console.log("data>>>4000",data400 && data400);

  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       const response = await fetch(`https://chatbotapi.mygatein.com/chatbot/KBot/getid?iddata={"AccountNumber":4000}`);
  //          const data = await response.json();
  //          setData400(JSON.parse(data[0]?.jsonData));
  //         }
  //     catch (error) {
  //       console.error("Error fetching data:", error);
  //     }
  //   }
  //   fetchData()
  // } , [])

  // const sendtobackend = (value,) => {
  //   console.log(value);
  // }

  // const steps = [
  //   {
  //     id: "1",
  //     message:
  //       "Hello Beautiful! Welcome to Dr. Musaed Al Zallal Clinics. What can I do to reveal your beauty? ",
  //     trigger: "2",
  //   },
  //   {
  //     id: "2",
  //     options: [
  //       {
  //         value: "appointments",
  //         label: "Book An Appointment",
  //         trigger: "gendar",
  //       },
  //       { value: "Services", label: "View our Services ", trigger: "gendar" },
  //       { value: "Branches", label: "Our Branches", trigger: "Branches" },
  //       {
  //         value: "Opening Hours",
  //         label: "Opening Hours",
  //         trigger: "Opening Hours",
  //       },
  //       {
  //         value: "Know more about Us",
  //         label: `Know more about Us `,
  //         trigger: "Know more about Us",
  //       },
  //     ],
  //   },
  //   {
  //     id: "gendar",
  //     options: [
  //       { value: "Male ", label: "male", trigger: "select department" },
  //       { value: "Famale ", label: "Famale", trigger: "select department" },
  //     ],
  //   },
  //   {
  //     id: "select department",
  //     message: "Please select the department",
  //     trigger: "department",
  //   },

  //   {
  //     id: "department",
  //     options: [
  //       {
  //         value: "Dermatology & Laser ",
  //         label: "Dermatology & Laser",
  //         trigger: "Dermatology & Laser",
  //       },
  //       { value: "Facial Laser (SR XXX) ", label: "Facial Laser (SR XXX)" },
  //       { value: "View our track record ", label: "View our track record" },
  //     ],
  //   },
  //   {
  //     id: "Dermatology & Laser",
  //     component: (
  //       <div style={{ backgroundColor: "#e7eaec" }}>
  //         <SliderCards img_one={LaserTreatment} img_tow={FacialLaser} />
  //       </div>
  //     ),
  //     trigger: "Facial Laser - View our track record",
  //   },
  //   {
  //     id: "Facial Laser - View our track record",
  //     options: [
  //       {
  //         value: "Facial Laser - View our track record",
  //         label: "view our track record",
  //         trigger: "showLider Facial Laser - View our track record",
  //       },
  //       {
  //         value: "Go back to main meun",
  //         label: "Go back to main meun",
  //         trigger: "options",
  //       },
  //     ],
  //   },
  //   {
  //     id: "showLider Facial Laser - View our track record",
  //     component: (
  //       <div style={{ backgroundColor: "#e7eaec" }}>
  //         <SliderImgs img_one={IMG_8703} img_tow={lipaugmentation} />
  //       </div>
  //     ),
  //     trigger: "explore all doctors",
  //   },
  //   {
  //     id: "explore all doctors",
  //     options: [
  //       {
  //         value: "Explore all doctors",
  //         label: "explore all doctors",
  //         trigger: "show all doctors",
  //       },
  //       {
  //         value: "Go back to main meun",
  //         label: "Go back to main meun",
  //         trigger: "options",
  //       },
  //     ],
  //   },
  //   {
  //     id: "show all doctors",
  //     // asMessage:true,
  //     component: (
  //       <div style={{ backgroundColor: "#e7eaec" }}>
  //         <SliderCards img_three={khlood} img_four={Louie} />
  //       </div>
  //     ),
  //     trigger: "book an appointment all doctors",
  //   },
  //   {
  //     id: "book an appointment all doctors",
  //     options: [
  //       {
  //         value: "book an appointment ",
  //         label: "book an appointment",
  //         trigger: "show form",
  //       },
  //     ],
  //   },
  //   {
  //     id: "show form",
  //     component: (
  //       <div>
  //         <ChatForm />
  //       </div>
  //     ),
  //   },
  //   // =======================
  //   {
  //     id: "Know more about Us",
  //     component: <a href="https://www.facebook.com/">feacebook</a>,
  //   },
  //   {
  //     id: "Opening Hours",
  //     component: (
  //       <div className={classes.openingHours}>
  //         <h4>
  //           As much as we would like to serve you around the clock. But our
  //           working hours are as follows:
  //         </h4>
  //         <ul>
  //           <li>Daily: 9:00 AM – 9:30 PM</li>
  //           <li>Thursday: 9:00 AM -9:00 PM </li>
  //         </ul>
  //       </div>
  //     ),
  //   },
  //   {
  //     id: "Branches",
  //     message: "Select the branch closest to you",
  //     trigger: "myBranches",
  //   },

  //   {
  //     id: "myBranches",
  //     options: [
  //       { value: "Al Khobar", label: "Al Khobar", trigger: "Al Khobar" },
  //       { value: "Riyadh", label: "Riyadh", trigger: "Riyadh" },
  //     ],
  //   },
  //   {
  //     id: "Al Khobar",
  //     message: "Select the branch closest to you",
  //     trigger: "khobar",
  //   },
  //   {
  //     id: "khobar",
  //     component: (
  //       <div>
  //         <a
  //           href="https://clinicform.niyat.sa/"
  //           target={"_blank"}
  //           rel="noreferrer"
  //         >
  //           link
  //         </a>{" "}
  //       </div>
  //     ),
  //   },
  //   {
  //     id: "Riyadh",
  //     message: "Select the branch closest to you",
  //     // trigger:"Riyadh links",
  //     trigger: () => {
  //       // Define your custom onClick function here
  //       // sendtobackend('Riyadh links');
  //       return "Riyadh links"; // You can specify the next trigger or step
  //     },
  //     // trigger: ({ value, steps }) => {
  //     //   sendtobackend(value,steps);
  //     // },
  //   },
  //   {
  //     id: "Riyadh links",
  //     component: (
  //       <div className={classes.Riyadh_links}>
  //         <ul>
  //           <li>
  //             <a
  //               href="https://clinicform.niyat.sa/"
  //               target={"_blank"}
  //               rel="noreferrer"
  //             >
  //               Al Rawdah Branch
  //             </a>{" "}
  //           </li>
  //           <li>
  //             {" "}
  //             <a
  //               href="https://clinicform.niyat.sa/"
  //               target={"_blank"}
  //               rel="noreferrer"
  //             >
  //               King Abdullah Branch{" "}
  //             </a>{" "}
  //           </li>
  //           <li>
  //             <a
  //               href="https://clinicform.niyat.sa/"
  //               target={"_blank"}
  //               rel="noreferrer"
  //             >
  //               Olaya Branch{" "}
  //             </a>{" "}
  //           </li>
  //           <li>
  //             <a
  //               href="https://clinicform.niyat.sa/"
  //               target={"_blank"}
  //               rel="noreferrer"
  //             >
  //               Badiah Branch{" "}
  //             </a>{" "}
  //           </li>
  //         </ul>
  //       </div>
  //     ),
  //   },
  //   // {
  //   //   id: "Riyadh links",
  //   //   component: <div className={classes.Riyadh_links}></div>,
  //   // },
  // ];

  // const steps = [
  //   {
  //     id: "1",
  //     message:
  //       "Hello Beautiful! Welcome to Dr. Musaed Al Zallal Clinics. What can I do to reveal your beauty? ",
  //     trigger: "options",
  //   },
  //   {
  //     id: "options",
  //     options: [
  //       {
  //         value: "appointments",
  //         label: "Book An Appointment",
  //         trigger: "gendar",
  //       },
  //       { value: "Services", label: "View our Services ", trigger: "gendar" },
  //       { value: "Branches", label: "Our Branches" },
  //       {
  //         value: "Opening Hours",
  //         label: "Opening Hours",
  //       },
  //       {
  //         value: "Know more about Us",
  //         label: `Know more about Us `,
  //       },
  //     ],
  //   },
  //   {
  //     id: "gendar",
  //     options: [
  //       { value: "Male ", label: "male", trigger: "select department" },
  //       { value: "Famale ", label: "Famale", trigger: "select department" },
  //     ],
  //   },
  //   {
  //     id: "select department",
  //     message: "Please select the department",
  //     trigger: "department",
  //   },

  //   {
  //     id: "department",
  //     options: [
  //       {
  //         value: "Dermatology & Laser ",
  //         label: "Dermatology & Laser",
  //         trigger: "Dermatology & Laser",
  //       },
  //       { value: "Facial Laser (SR XXX) ", label: "Facial Laser (SR XXX)" },
  //       { value: "View our track record ", label: "View our track record" },
  //     ],
  //   },
  //   {
  //     id: "Dermatology & Laser",
  //     imageCarousel: [
  //       {
  //         path: "https://img.youm7.com/large/62016109533131aa.jpg",
  //         text: "antonious nasr kamel youssef jo",
  //       },
  //       {
  //         path:
  //           "https://www.nawaret.com/img1/2022/02/%D8%A3%D8%AD%D9%85%D8%AF-%D8%A7%D9%84%D8%B9%D9%88%D8%B6%D9%8A.jpeg",
  //         text: "nasr",
  //       },
  //       {
  //         path: "https://img.youm7.com/large/62016109533131aa.jpg",
  //         text: "akram",
  //       },
  //     ],
  //     trigger: "Facial Laser - View our track record",
  //   },
  //   // {
  //   //   id: "Facial Laser - View our track record",
  //   //   text: "please choose your favorite day",
  //   //   dates: [
  //   //     {
  //   //       value: "Facial Laser - View our track record",
  //   //       label: "view our track record",
  //   //     },
  //   //     {
  //   //       value: "Go back to main meun",
  //   //       label: "Go back to main meun",
  //   //     },
  //   //   ],
  //   // },
  //   {
  //     id: "Facial Laser - View our track record",
  //     forms: [
  //       {
  //         value: "name",
  //         type: "text",
  //       },
  //       {
  //         value: "email",
  //         type: "email",
  //       },
  //       {
  //         value: "phone",
  //         type: "phone",
  //       },
  //     ],
  //   },
  // ];

  // const steps = [
  //   {
  //     id: "1",
  //     message:
  //       "Hello Beautiful! Welcome to Dr. Musaed Al Zallal Clinics. What can I do to reveal your beauty? ",
  //     trigger: "options",
  //   },
  //   {
  //     id: "options",
  //     options: [
  //       {
  //         value: "appointments",
  //         label: "Book An Appointment",
  //         trigger: "gendar",
  //       },
  //       { value: "Services", label: "View our Services ", trigger: "gendar" },
  //       { value: "Branches", label: "Our Branches", trigger: "Branches" },
  //       {
  //         value: "Opening Hours",
  //         label: "Opening Hours",
  //         trigger: "Opening Hours",
  //       },
  //       {
  //         value: "Know more about Us",
  //         label: `Know more about Us `,
  //         trigger: "Know more about Us",
  //       },
  //     ],
  //   },
  //   {
  //     id: "gendar",
  //     options: [
  //       { value: "Male ", label: "male", trigger: "select department" },
  //       { value: "Famale ", label: "Famale", trigger: "select department" },
  //     ],
  //   },
  //   {
  //     id: "select department",
  //     message: "Please select the department",
  //     trigger: "department",
  //   },

  //   {
  //     id: "department",
  //     options: [
  //       {
  //         value: "Dermatology & Laser ",
  //         label: "Dermatology & Laser",
  //         trigger: "Dermatology & Laser",
  //       },
  //       { value: "Facial Laser (SR XXX) ", label: "Facial Laser (SR XXX)" },
  //       { value: "View our track record ", label: "View our track record" },
  //     ],
  //   },
  //   {
  //     id: "Dermatology & Laser",
  //     images: [
  //       { path: "https://img.youm7.com/large/62016109533131aa.jpg" },
  //       {
  //         path:
  //           "https://www.nawaret.com/img1/2022/02/%D8%A3%D8%AD%D9%85%D8%AF-%D8%A7%D9%84%D8%B9%D9%88%D8%B6%D9%8A.jpeg",
  //       },
  //       { path: "https://img.youm7.com/large/62016109533131aa.jpg" },
  //     ],
  //     trigger: "Facial Laser - View our track record",
  //   },
  //   // {
  //   //   id: "Facial Laser - View our track record",
  //   //   options: [
  //   //     {
  //   //       value: "Facial Laser - View our track record",
  //   //       label: "view our track record",
  //   //       trigger: "showLider Facial Laser - View our track record",
  //   //     },
  //   //     {
  //   //       value: "Go back to main meun",
  //   //       label: "Go back to main meun",
  //   //       trigger: "options",
  //   //     },
  //   //   ],
  //   // },
  //   {
  //     id: "Facial Laser - View our track record",
  //     dates: [
  //       {
  //         value: "Facial Laser - View our track record",
  //         label: "view our track record",
  //       },
  //       {
  //         value: "Go back to main meun",
  //         label: "Go back to main meun",
  //       },
  //     ],
  //     // trigger: "showLider Facial Laser - View our track record",
  //   },
  //   // {
  //   //   id: "showLider Facial Laser - View our track record",
  //   //   component: (
  //   //     <div style={{ backgroundColor: "#e7eaec" }}>
  //   //       <SliderCards
  //   //         theURL={
  //   //           "https://chatbotapi.mygatein.com/wwwroot/5F3D7700FA011028.png"
  //   //         }
  //   //       />
  //   //     </div>
  //   //   ),
  //   //   trigger: "explore all doctors",
  //   // },
  //   // {
  //   //   id: "explore all doctors",
  //   //   options: [
  //   //     {
  //   //       value: "Explore all doctors",
  //   //       label: "explore all doctors",
  //   //       trigger: "show all doctors",
  //   //     },
  //   //     {
  //   //       value: "Go back to main meun",
  //   //       label: "Go back to main meun",
  //   //       trigger: "options",
  //   //     },
  //   //   ],
  //   // },
  //   // {
  //   //   id: "show all doctors",
  //   //   // asMessage:true,
  //   //   component: (
  //   //     <div style={{ backgroundColor: "#e7eaec" }}>
  //   //       <SliderCards
  //   //         theURL={
  //   //           "https://chatbotapi.mygatein.com/wwwroot/5F3D7700FA011028.png"
  //   //         }
  //   //       />
  //   //     </div>
  //   //   ),
  //   //   trigger: "book an appointment all doctors",
  //   // },
  //   // {
  //   //   id: "book an appointment all doctors",
  //   //   options: [
  //   //     {
  //   //       value: "book an appointment ",
  //   //       label: "book an appointment",
  //   //       trigger: "show form",
  //   //     },
  //   //   ],
  //   // },
  //   // {
  //   //   id: "show form",
  //   //   component: (
  //   //     <div>
  //   //       <ChatForm />
  //   //     </div>
  //   //   ),
  //   // },
  //   // // =======================
  //   // {
  //   //   id: "Know more about Us",
  //   //   component: <a href="https://www.facebook.com/">feacebook</a>,
  //   // },
  //   // {
  //   //   id: "Opening Hours",
  //   //   component: (
  //   //     <div className={classes.openingHours}>
  //   //       <h4>
  //   //         As much as we would like to serve you around the clock. But our
  //   //         working hours are as follows:
  //   //       </h4>
  //   //       <ul>
  //   //         <li>Daily: 9:00 AM – 9:30 PM</li>
  //   //         <li>Thursday: 9:00 AM -9:00 PM </li>
  //   //       </ul>
  //   //     </div>
  //   //   ),
  //   // },
  //   // {
  //   //   id: "Branches",
  //   //   message: "Select the branch closest to you",
  //   //   trigger: "myBranches",
  //   // },

  //   // {
  //   //   id: "myBranches",
  //   //   options: [
  //   //     { value: "Al Khobar", label: "Al Khobar", trigger: "Al Khobar" },
  //   //     { value: "Riyadh", label: "Riyadh", trigger: "Riyadh" },
  //   //   ],
  //   // },
  //   // {
  //   //   id: "Al Khobar",
  //   //   message: "Select the branch closest to you",
  //   //   trigger: "khobar",
  //   // },
  //   // {
  //   //   id: "khobar",
  //   //   component: (
  //   //     <div>
  //   //       <a
  //   //         href="https://clinicform.niyat.sa/"
  //   //         target={"_blank"}
  //   //         rel="noreferrer"
  //   //       >
  //   //         link
  //   //       </a>{" "}
  //   //     </div>
  //   //   ),
  //   // },
  //   // {
  //   //   id: "Riyadh",
  //   //   message: "Select the branch closest to you",
  //   //   // trigger:"Riyadh links",
  //   //   trigger: () => {
  //   //     // Define your custom onClick function here
  //   //     // sendtobackend('Riyadh links');
  //   //     return "Riyadh links"; // You can specify the next trigger or step
  //   //   },
  //   //   // trigger: ({ value, steps }) => {
  //   //   //   sendtobackend(value,steps);
  //   //   // },
  //   // },
  //   // {
  //   //   id: "Riyadh links",
  //   //   component: (
  //   //     <div className={classes.Riyadh_links}>
  //   //       <ul>
  //   //         <li>
  //   //           <a
  //   //             href="https://clinicform.niyat.sa/"
  //   //             target={"_blank"}
  //   //             rel="noreferrer"
  //   //           >
  //   //             Al Rawdah Branch
  //   //           </a>{" "}
  //   //         </li>
  //   //         <li>
  //   //           {" "}
  //   //           <a
  //   //             href="https://clinicform.niyat.sa/"
  //   //             target={"_blank"}
  //   //             rel="noreferrer"
  //   //           >
  //   //             King Abdullah Branch{" "}
  //   //           </a>{" "}
  //   //         </li>
  //   //         <li>
  //   //           <a
  //   //             href="https://clinicform.niyat.sa/"
  //   //             target={"_blank"}
  //   //             rel="noreferrer"
  //   //           >
  //   //             Olaya Branch{" "}
  //   //           </a>{" "}
  //   //         </li>
  //   //         <li>
  //   //           <a
  //   //             href="https://clinicform.niyat.sa/"
  //   //             target={"_blank"}
  //   //             rel="noreferrer"
  //   //           >
  //   //             Badiah Branch{" "}
  //   //           </a>{" "}
  //   //         </li>
  //   //       </ul>
  //   //     </div>
  //   //   ),
  //   // },
  //   // // {
  //   // //   id: "Riyadh links",
  //   // //   component: <div className={classes.Riyadh_links}></div>,
  //   // // },
  // ];

  // const updatedSteps = steps.map((step, index) => {
  //   // Clone the step to avoid modifying the original object
  //   const updatedStep = { ...step };

  //   // switch (index) {
  //   //   case 4:
  //   //     console.log("hey", updatedStep.options.map((step,index) =>{
  //   //       if(index ===0){
  //   //         console.log(step.length > 0 && step)
  //   //       }
  //   //    }));
  //   //     break;
  //   //   case 5:
  //   //     updatedStep.component = <div>5</div>;
  //   //     break;
  //   //   case 7:
  //   //     updatedStep.component = <div>7</div>;
  //   //     break;
  //   //   case 9:
  //   //     updatedStep.component = <div>9</div>;
  //   //     break;
  //   //   case 10:
  //   //     updatedStep.component = <div>10</div>;
  //   //     break;
  //   //   case 11:
  //   //     updatedStep.component = <div>11</div>;
  //   //     break;
  //   //   case 12:
  //   //     updatedStep.component = <div>12</div>;
  //   //     break;
  //   //   case 15:
  //   //     updatedStep.component = <div>15</div>;
  //   //     break;
  //   //   case 17:
  //   //     updatedStep.component = <div>17</div>;
  //   //     break;
  //   //   case 18:
  //   //     updatedStep.component = <div>18</div>;
  //   //     break;
  //   //   default:
  //   //     // Handle the default case if needed
  //   //     break;
  //   // }

  //   // updatedStep.images && (
  //   //   <div style={{ backgroundColor: "#e7eaec" }}>
  //   //     <SliderCards theURL={updatedStep.images} />
  //   //   </div>
  //   // );

  //   // updatedStep.component = (
  //   //   <div style={{ backgroundColor: "#e7eaec" }}>
  //   //     <SliderCards theURL={updatedStep.images} />
  //   //   </div>
  //   // );

  //   if (updatedStep.imageCarousel) {
  //     // Set the component property for image steps
  //     updatedStep.component = (
  //       <div style={{ backgroundColor: "#e7eaec" }}>
  //         <SliderCards theURL={updatedStep.imageCarousel} />
  //       </div>
  //     );
  //   } else if (updatedStep.dates) {
  //     updatedStep.component = (
  //       <div style={{ backgroundColor: "#e7eaec" }}>
  //         <DateInput theHeader={updatedStep.text} />
  //       </div>
  //     );
  //   }

  //   return updatedStep;
  // });

  const steps = [
    [
      { id: "1", message: "Welcome", trigger: "T1" },
      {
        id: "T1",
        component: (
          <DateInput
            disabledDates={[
              "2023-10-27T22:00:00.000Z",
              "2023-10-28T22:00:00.000Z",
            ]}
            disabledDays={[5]}
          />
        ),
        waitAction: true,
        trigger: "nextStepAfterDateInput",
      },
      {
        id: "nextStepAfterDateInput",
        message: ({ previousValue }) => `${previousValue}`,
      },
    ],
  ];

  const updatedSteps = data.map((step, index) => {
    const updatedStep = { ...step };
    if (updatedStep.imageCarousel) {
      // Set the component property for image steps
      updatedStep.component = (
        <div style={{ backgroundColor: "#e7eaec" }}>
          <SliderCards theURL={updatedStep.imageCarousel} />
        </div>
      );
    } else if (updatedStep.forms) {
      updatedStep.component = (
        <div style={{ backgroundColor: "#e7eaec" }}>
          <ChatForm allForms={updatedStep.forms} />
        </div>
      );
    }

    return updatedStep;
  });

  const triggers = data.map((step) => step.trigger);
  console.log("triggers>>>", triggers);

  return steps.length > 0 ? (
    <ChatBot steps={updatedSteps} botAvatar={logo} headerTitle="Niyat-نياط" />
  ) : (
    <div style={{ color: "white" }}>Loading... </div>
  );
};

export default InterviewChatbot;
