// import React, { Component } from "react";
// import Slider from "react-slick";
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";
// import MediaCard from "./card";
// const SliderCards = ({ theURL }) => {
//   console.log("al url", theURL);
//   var settings = {
//     infinite: true,
//     speed: 500,
//     slidesToShow: 1,
//     slidesToScroll: 1,
//     // autoplay: true,
//   };

//   return (
//     <div style={{ width: "250px", height: "100%" }}>
//       <Slider {...settings}>
//         {theURL?.map((el, index) => (
//           <MediaCard
//             img={el.path && el.path }
//             // title="Facial Laser"
//             // price="SR 1,600"
//             // button="View our track record"
//           />
//         ))}
//         ;{/* <div> */}
//         {/* <img src={el.path} alt="test" style={{ maxHeight: "100px" }} /> */}
//         {/* </div> */}
//       </Slider>
//     </div>
//   );
// };

// export default SliderCards;

import React, { Component } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import MediaCard from "./card";
const SliderCards = ({ theURL }) => {
  console.log("al url", theURL);
  var settings = {
    infinite: true,
    speed: 100,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
  };

  return (
    <div style={{ width: "250px", height: "100%" }}>
      <Slider {...settings}>
        {theURL?.map((el, index) => (
          <MediaCard img={el.path && el.path} title={el.text} counting={index} />
        ))}
      </Slider>
    </div>
  );
};

export default SliderCards;
